<template>
<div class="container padding-container">
	<el-button class="topBtns" type="primary" icon="el-icon-plus" @click="handleEditRoleShow()">新增角色</el-button>
	<div class="border-container">
		<el-row class="info-container" type="flex" align="middle" justify="space-between">
			<span class="list-title">角色列表</span>
		</el-row>
		<div class="table-container">
			<el-table :data="roleList" tooltip-effect="dark" style="width: 100%" size="medium"
				:header-cell-style="{height: '40px', background: 'linear-gradient(180deg,rgba(251,252,253,1) 0%,rgba(246,248,250,1) 100%)'}"
				:cell-style="{height: '50px',padding: '5px 0'}" header-align="center">
				<el-table-column prop="name" label="角色名称" min-width="155" :show-overflow-tooltip="true"
					align="center">
					<template slot-scope="scope">
						{{scope.row.name}}
					</template>
				</el-table-column>
				<el-table-column prop="create_time" label="创建时间" min-width="155" align="center">
					<template slot-scope="scope">
						{{scope.row.create_time | timeFilter}}
					</template>
				</el-table-column>
				<el-table-column label="操作" min-width="155" align="center">
					<template slot-scope="scope">
						<allot-authority :detail='scope.row' class="icon-btn" :serviceType='serviceType'/>
                        <el-button type="text" class="icon-btn" @click="handleEditRoleShow(scope.row)">
                            <el-tooltip placement="top" content="修改角色名称">
                                <i class="iconfont icon-edit"></i>
                            </el-tooltip>
                        </el-button>
                        <el-button type="text" class="icon-btn" @click="handleRoleDelete(scope.row)">
                            <el-tooltip placement="top" content="删除角色">
                                <i class="iconfont icon-delete"></i>
                            </el-tooltip>
                        </el-button>
					</template>
				</el-table-column>
				<div class="" slot="empty">
					<no-data></no-data>
				</div>
			</el-table>
		</div>
		<div class="page-container"></div>
	</div>
	<div class="authority-dialog">
		<el-dialog :title="selectItem.id ? '修改角色名' : '新增角色'" :visible.sync="dialogVisible" @close="handleEditRoleClose" width="500px">
			<div class="dialog-content">
				<div class="content-floor">
					<span class="key">角色名称</span>
					<el-input v-model="editForm.name" placeholder="请输入角色名称"></el-input>
				</div>
			</div>
			<div slot="footer" class="dialog-footer" style="display: flex;justify-content: center">
				<el-button type="primary" size="medium" @click="handleEditRoleConfirm">保存</el-button>
				<el-button class="cancel" size="medium" @click="handleEditRoleClose">取消</el-button>
			</div>
		</el-dialog>
	</div>
</div>
</template>

<script>
import Core from '../../core';
import { deepCopy } from '@/core/utils';
const USER_TYPE = Core.Const.USER_TYPE;

export default {
    components: {
        NoData: () => import('@/components/Empty.vue'),
        AllotAuthority: () => import('./components/AllotAuthority.vue'),
    },
    props: {},
    data() {
        return {
			serviceType: '',
			USER_TYPE,
            userType: Core.Data.getUserType(),

            total: 0,
            currentPage: 1,
			pageSize: 10,

            roleList: [],
			dialogVisible: false,
			selectItem: '',

			editForm: {
                name: ''
			}
        };
    },
    created() {
		this.getRoleList();
		this.getAccountType();
    },
    methods: {
		async getAccountType() {
            switch (this.userType) {
                case USER_TYPE.SCHOOL:
                    let org_id = Core.Data.getBelong().org_id
                    await Core.Api.Org.detail(org_id).then(res => {
                        this.serviceType = res.detail.service_type
                    })
					
                    break;
            }
        },

		getRoleList() { // 获取表格数据
            Core.Api.Authority.list().then((res) => {
				console.log("getRoleList -> res", res)
                this.roleList = res.list
            }).catch(err => {
				console.log("getRoleList -> err", err)
			});
		},

		handleEditRoleShow(item) { // 显示 新增/编辑 角色名称 弹框
			if (item) {
				this.selectItem = item
				this.editForm.name = item.name
			}
			this.dialogVisible = true
		},
		handleEditRoleClose() { // 关闭 新增/编辑 角色名称 弹框
			this.dialogVisible = false
			this.editForm.name = ''
			this.selectItem = ''
		},
		handleEditRoleConfirm() { // 确定 新增/编辑 角色名称
			let id = this.selectItem ? this.selectItem.id : 0
			if (!this.editForm.name) {
				return this.$message.warning('请输入角色名称')
			}
			Core.Api.Authority.save(
				id,
				this.editForm.name
			).then((res) => {
				this.$message.success(id ? '修改成功' : '创建成功')
				this.getRoleList()
				this.handleEditRoleClose()
			}).catch(err => {
				console.log("handleEditRoleConfirm -> err", err)
			});
		},

		handleRoleDelete(item) { // 删除 角色
			this.$confirm('确定要删除该角色吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				Core.Api.Authority.delete(item.id).then((res) => {
					this.$message.success('删除成功!');
					this.getRoleList()
				}).catch(err => {
					console.log("handleRoleDelete -> err", err)
				});
			});
		},
    }
};
</script>

<style lang="scss" scoped>
.container {
    @import '@/common/styles/table.scss';
	.authority-dialog {
		.dialog-content {
			.content-floor {
				display: flex;
				align-items: center;
                + .content-floor {
                    margin-top: 20px;
                }
				.key {
					display: block;
					width: 120px;
				}
			}
		}
	}
}
</style>
